<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('menu.agentRegistration.list')"
    class="px-5 py-3"
  >
    <base-dropdown-filter :items="filters" @changeFilter="changeFilter" label="Status Registrasi">
    </base-dropdown-filter>
    <v-btn @click="exportData" color="success"> Export </v-btn>
    <!--    <a-->
    <!--      type="button"-->
    <!--      :href="-->
    <!--        `http://192.168.0.11:1948/api/agent_regs/export_excel?url=registration-management%2Fagents`-->
    <!--      "-->
    <!--      download="file.xlsx"-->
    <!--    >-->
    <!--      <button @click="exportData()" class="btn btn-primary">-->
    <!--        Export-->
    <!--      </button>-->
    <!--    </a>-->
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">ID</th>
          <th class="primary--text">
            {{ $t('agentRegistration.name') }}
          </th>
          <th class="primary--text">
            {{ $t('agentRegistration.phone') }}
          </th>
          <th class="primary--text">
            {{ $t('agentRegistration.email') }}
          </th>
          <th class="primary--text">
            {{ $t('agentRegistration.officeName') }}
          </th>
          <th class="primary--text">
            {{ $t('agentRegistration.status') }}
          </th>
          <th class="text-center primary--text">
            {{ $t('general.openDetail') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.phone_number }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.agent_office_name }}</td>
          <td>{{ item.status }}</td>
          <td>
            <base-detail-button
              :permissions="permissions"
              :basePath="basePath"
              :id="item.id"
            ></base-detail-button>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions', 'filters'],
  data() {
    return {
      downloadUrl: '#',
    };
  },
  components: {
    Pagination,
  },
  computed: {
    ...mapState({
      basePath: (state) => state.agentRegistration.basePath,
    }),
  },
  methods: {
    async exportData() {
      await this.$store.dispatch('agentRegistration/export');
    },
    changePage(page) {
      this.$emit('changePage', page);
    },
    changeFilter(val) {
      this.$emit('changeFilter', val);
    },
  },
};
</script>
